import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ProgressBar } from "primereact/progressbar"

import { capitalizedFirst } from "src/utils/capitalized-first-character"
import EmptyPage from "../empty-page"
import { getMegaLiveUser } from "src/services/mega-live-service"
import { MegaLiveType } from "src/components/modal/mega-live-modal/types"
import MegaLiveTable from "src/components/modal/mega-live-modal/mega-live-table"
import { InputText } from "primereact/inputtext"
import { useDebounce } from "use-debounce"
import Loading from "src/components/modal/mega-live-modal/loading"

export default function HomePage() {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [isNotHaveData, setIsNotHaveData] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [megaLives, setMegaLives] = useState<MegaLiveType[]>([])
  const [megaLivesDisplay, setMegaLivesDisplay] = useState<MegaLiveType[]>([])
  const [search, searchValue] = useState<string>("")
  const [isSearch, setIsSearch] = useState<boolean>(false)
  const [searchDebounce] = useDebounce(search, 800)

  const handleSearch = (newValue: string) => {
    searchValue(newValue)
  }

  useEffect(() => {
    handleSearchValue()
  }, [searchDebounce])

  const handleSearchValue = () => {
    setIsSearch(true)
    const searchResult = [] as MegaLiveType[]
    const regex = new RegExp(search, "i")
    megaLives.forEach(i => {
      if(regex.test(i.name) || regex.test(i.email)) searchResult.push(i)
    })
    setMegaLivesDisplay(searchResult)
    setTimeout(() => {
      setIsSearch(false)
    }, 1000)
  }

  const getMegaLive = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getMegaLiveUser().then((res: any) => {
      if(res?.data?.data?.length) {
        setMegaLives(res.data.data)
        setMegaLivesDisplay(res.data.data)
      } else {
        setIsNotHaveData(true)
        setErrorMessage(capitalizedFirst(t("global.no-data-found")))
      }
    }).catch(() => {
      setIsNotHaveData(true)
      setErrorMessage(capitalizedFirst(t("global.no-data-found")))
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    getMegaLive()
  }, [])

  if(isNotHaveData && errorMessage && !loading) return (
    <div className="flex h-screen px-3 text-center items-center justify-center">
      <EmptyPage message={errorMessage}/>
    </div>
  )

  return loading ? (
    <div className="flex h-[540px] items-center justify-center">
      <ProgressBar
        mode="indeterminate"
        style={{ height: "5px", width: "50%", maxWidth: "300px" }}
      ></ProgressBar>
    </div>
  ) : (
    <div className="mt-[24px] md:mt-[96px] flex h-full w-full flex-col items-center justify-center rounded-3 bg-white py-2">
      <div className="flex w-full max-w-[1200px] flex-col items-center gap-2 px-[12px]">
        <span className="mt-[20px] text-[16px] font-medium leading-[24px]">
          {t("home-page.module.mega-live")}
        </span>
        <div className="w-full flex flex-col gap-1 max-w-[900px] border border-gray-300 rounded-3 w-fit p-4">
          <div className="pt-4px">
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                className="w-full md:w-[367px] h-[44px]"
                placeholder={capitalizedFirst(t("global.search"))}
                value={search}
                onChange={(event) => handleSearch(event.target.value)}
              />
            </span>
          </div>
          <div className="mt-3 min-h-[300px] flex items-center justify-center bg-gray-white">
            {isSearch ? <div className="flex items-center justify-center w-full h-full m-auto"><Loading /></div> : null}
            {!megaLivesDisplay.length && !isSearch ? <EmptyPage message={capitalizedFirst(t("global.no-data-found"))}/> : null}
            {megaLivesDisplay.length && !isSearch ? <MegaLiveTable megaLives={megaLivesDisplay} /> : null}          
          </div>
        </div>
      </div>
    </div>
  )
}
