/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import { DataTable } from "primereact/datatable"
import { MegaLiveTableType, MegaLiveType } from "./types"
import { Column } from "primereact/column"
import ImgCustom from "src/components/img-custom"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"

export default function MegaLiveTable(props: MegaLiveTableType) {
  const { megaLives } = props
  const { t } = useTranslation()
  const AvatarTemplate = (rowData: MegaLiveType) => {
    return (
      <div>
        <ImgCustom
          url={rowData.id}
          alt="shopImage"
          isShopImage={true}
          className="h-[40px] w-[40px] rounded-full"
        />
      </div>
    )
  }
  const NameTemplate = (rowData: MegaLiveType) => {
    return (
      <span className="inline-block font-14 max-w-[300px] whitespace-normal font-medium leading-20 text-gray-700">
        {rowData.name}
      </span>
    )
  }
  const EmailTemplate = (rowData: MegaLiveType) => {
    return (
      <span className="inline-block font-14 max-w-[300px] break-words font-medium leading-20 text-gray-700">
        {rowData.email}
      </span>
    )
  }
  const ActionTemplate = (rowData: MegaLiveType) => {
    const handleGo = () => {
      const url = `${process.env.REACT_APP_MEGA_LIVE}/mega-live/${rowData.id}?permission=user`
      window.open(url, "_self")
    }
    return (
      <div className="flex items-center justify-end">
        <button
          className="rounded-3 bg-blue-600 px-3 py-1 text-[14px] font-medium leading-[20px] text-white hover:bg-blue-500"
          onClick={handleGo}
        >
          {capitalizedFirst(t("home-page.go"))}
        </button>
      </div>
    )
  }

  const tableFields = [
    {
      field: "avatar_thumbnail_url",
      label: "",
      customStyle: {
        width: "60px",
        minWidth: "60px",
      },
      template: AvatarTemplate,
    },
    {
      field: "name",
      label: capitalizedFirst(t("home-page.shop-name")),
      customStyle: {},
      template: NameTemplate,
    },
    {
      field: "email",
      label: capitalizedFirst(t("home-page.shop-email")),
      customStyle: {
        width: "150px",
      },
      template: EmailTemplate,
    },
    {
      field: "id",
      label: "",
      customStyle: {
        width: "60px",
      },
      template: ActionTemplate,
    },
  ]

  if (!megaLives.length) return null
  return (
    <div className="shopping-product-table w-full h-[500px] border border-gray-300 max-h-[500px] overflow-y-scroll no-scrollbar" id="customTable">
      <DataTable value={megaLives} dataKey="id">
        {tableFields.map((item, index) => (
          <Column
            key={index}
            field={item.field}
            header={item.label}
            bodyStyle={item.customStyle}
            body={item.template}
          />
        ))}
      </DataTable>
    </div>
  )
}
